import {
  GET_MORE_TRENDING_PENDING,
  GET_MORE_TRENDING_SUCCESS,
  GET_TRENDING_PENDING,
  GET_TRENDING_SUCCESS,
} from "../../actions/actionTypes";

// reducers/dataReducer.js
const initialState = {
  pending: false,
  loadMorePending: false,
  trending: [],
  error: false,
};

export default function trendingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRENDING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_MORE_TRENDING_PENDING:
      return {
        ...state,
        loadMorePending: true,
      };
    case GET_TRENDING_SUCCESS:
      return {
        ...state,
        pending: false,
        trending: action.payload.trending,
      };
    case GET_MORE_TRENDING_SUCCESS:
      const newTrendingList = action.payload.trending;
      const { trending } = state;
      return {
        ...state,
        loadMorePending: false,
        trending: [...trending, ...newTrendingList],
      };
    default:
      return state;
  }
}

export function fetchTrendingPending() {
  return {
      type: GET_TRENDING_PENDING,
  };
}

export function fetchMoreTrendingPending() {
  return {
      type: GET_MORE_TRENDING_PENDING,
  };
}

export function fetchTrendingSuccess(trending) {
  return {
      type: GET_TRENDING_SUCCESS,
      payload: {
          trending: trending,
      },
  };
}

export function fetchMoreTrendingSuccess(trending) {
  return {
      type: GET_MORE_TRENDING_SUCCESS,
      payload: {
          trending: trending,
      },
  };
}
