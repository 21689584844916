import { GET_QUIZ_DATA_PENDING, GET_QUIZ_DATA_SUCCESS } from "../../actions/actionTypes";

const initialState = {
    pending: false,
    loadMorePending: false,
    quizlist: {},
    error: false,
  };

/* reducer function for Quiz data */
export const quizReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_QUIZ_DATA_PENDING:
            return {
                ...state,
                pending: true,
            };

        case GET_QUIZ_DATA_SUCCESS:
            const newQuizList = action.payload.quizlist;
            return {
                ...state,
                loadMorePending: false,
                quizlist: newQuizList
            };
                
        default:
            return state;
    }
};


/* Action creators for Quiz data*/
export const fetchQuizDataByuserIdPending = () => ({
    type: GET_QUIZ_DATA_PENDING
});


export const fetchQuizDataByuserIdSuccess = (quizlist) => ({
    type: GET_QUIZ_DATA_SUCCESS,
    payload: {
        quizlist: quizlist
    }
});
