export const GET_TRENDING_PENDING = "getTrendingPending";
export const GET_MORE_TRENDING_PENDING = "getMoreTrendingPending";
export const GET_TRENDING_SUCCESS = "getTrendingSuccess";
export const GET_MORE_TRENDING_SUCCESS = "getMoreTrending";

export const GET_LATEST_PENDING = "getLatestPending";
export const GET_MORE_LATEST_PENDING = "getMoreLatestPending";
export const GET_LATEST_SUCCESS = "getLatestSuccess";
export const GET_MORE_LATEST_SUCCESS = "getMoreLatest";

export const GET_QUIZ_DATA_PENDING = "getQuizDataPending";
export const GET_QUIZ_DATA_SUCCESS  = "getQuizDataSuccess";

export const GET_QUIZ_QUES_DATA_PENDING = "getQuizQuesDataPending";
export const GET_QUIZ_QUES_DATA_SUCCESS  = "getQuizQuesDataSuccess";


