import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'
import { retry } from '../../utils/PromiseRetry';

const Home = lazy(() => retry(() => import('../components/Home')));
const Privacy = lazy(() => retry(() => import('../components/Privacy')));
const Terms = lazy(() => retry(() => import('../components/Terms')));
const AboutUs = lazy(() => retry(() => import('../components/AboutUs')));
const BecameNewsepick = lazy(() => retry(() => import('../components/BecameNewsepick')));
const WhyUs = lazy(() => retry(() => import('../components/WhyUs')));
const CSR = lazy(() => retry(() => import('../components/CSR')));
const Sustainability = lazy(() => retry(() => import('../components/Sustainability')));
const NotFound = lazy(() => retry(() => import('../components/404')));
const FaQ = lazy(() => retry(() => import('../components/FaQ')));
const FaQDetail = lazy(() => retry(() => import('../components/FaQ/FaQDetail')));
const HowToUse = lazy(() => retry(() => import('../components/HowToUse')));
const Blog = lazy(() => retry(() => import('../components/Blog')));
const BlogDetails = lazy(() => retry(() => import('../components/Blog/BlogDetails')));
const Media = lazy(() => retry(() => import('../components/Media')));
const MediaKit = lazy(() => retry(() => import('../components/MediaKit')));
const UserStories = lazy(() => retry(() => import('../components/UserStories')));
const FaqDetailsPage = lazy(() => retry(() => import('../components/FaQ/FaqDetailsPage')));
const ContactUs = lazy(() => retry(() => import('../components/ContactUs')));
const BlogCategory = lazy(() => retry(() => import('../components/Blog/BlogCategoryPage')));
const InstitutionStatic = lazy(() => retry(() => import('../components/Home/Institution')));
const IndividualStatic = lazy(() => retry(() => import('../components/Home/Individuals')));
const Solutions = lazy(() => retry(() => import('../components/Solutions')));
const SolutionDevelop = lazy(() => retry(() => import('../components/Solutions/Develop')));
const SolutionEvolve = lazy(() => retry(() => import('../components/Solutions/Evolve')));
const PricingPage = lazy(() => retry(() => import('../components/Pricing/PricingPage')));
const ThankYouPage = lazy(() => retry(() => import('../components/ThankYouPage')));

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    children: [
      { path: "", element: <Home /> },
      { path: "privacy-policy", element: <Privacy /> },
      { path: "terms-condition", element: <Terms /> },
      { path: "company/about-us", element: <AboutUs /> },
      { path: "company/the-idea-that-became-newsepick", element: <BecameNewsepick /> },
      { path: "company/why-us", element: <WhyUs /> },
      { path: "company/csr", element: <CSR /> },
      { path: "company/sustainability", element: <Sustainability /> },
      { path: "help-center/faq", element: <FaQ /> },
      { path: "help-center/faq-category/:slug", element: <FaQDetail /> },
      { path: "help-center/how-to-use", element: <HowToUse /> },
      { path: "blogs", element: <Blog /> },
      { path: "blogs/:slug", element: <BlogDetails /> },
      { path: "media", element: <Media /> },
      { path: "media-kit", element: <MediaKit /> },      
      { path: "testimonials", element: <UserStories /> },
      { path: "/help-center/faq-details/:slug", element: <FaqDetailsPage /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "blogs/category/:slug", element: <BlogCategory /> },
      { path: "institutions", element: <InstitutionStatic /> },
      { path: "individuals", element: <IndividualStatic /> },
      { path: "solutions", element: <Solutions /> },
      { path: "solutions/develop", element: <SolutionDevelop /> },
      { path: "solutions/evolve", element: <SolutionEvolve /> },  
      { path: "pricing/:productType", element: <PricingPage /> },    
      { path: "submission-contact-us", element: <ThankYouPage /> },
      { path: "develop-submission-subscription", element: <ThankYouPage /> },
      { path: "evolve-submission-subscription", element: <ThankYouPage /> },
    ],
  },
]);


const AppRouter = () => {
  return (
    <Suspense fallback={
      <span style={{ textAlign: "center", display: "block" }}>
        <div
          style={{
            display: "inline-block",
            marginTop: "300px",
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={false}
            timeout={8000}
          />
        </div>
      </span>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default AppRouter;
