import { combineReducers } from "redux";
import latestReducer from "./LatestNews";
import trendingReducer from "./TrendingNewsReducers";
import {quizReducer } from "./QuizDataReducers";
import { quizQuesReducer } from "./QuizQuesReducers";

const rootReducer = combineReducers({
  trending: trendingReducer,
  latest: latestReducer,
  quizlist: quizReducer,
  quizQuesList: quizQuesReducer
});

export default rootReducer;
