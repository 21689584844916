import {
  GET_LATEST_PENDING,
  GET_LATEST_SUCCESS,
  GET_MORE_LATEST_PENDING,
  GET_MORE_LATEST_SUCCESS,
} from "../../actions/actionTypes";

// reducers/dataReducer.js
const initialState = {
  pending: false,
  loadMorePending: false,
  latest: [],
  error: false,
  count : 0
};

export default function latestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LATEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_MORE_LATEST_PENDING:
      return {
        ...state,
        loadMorePending: true,
      };
    case GET_LATEST_SUCCESS:
      return {
        ...state,
        pending: false,
        latest: action.payload.latest?.data,
        count: action.payload.latest?.count
      };
    case GET_MORE_LATEST_SUCCESS:
      const newLatestList = action.payload.latest?.data;
      const { latest } = state;
      return {
        ...state,
        loadMorePending: false,
        latest: [...latest, ...newLatestList],
        count: action.payload.latest?.count
      };
    default:
      return state;
  }
}

export function fetchLatestPending() {
  return {
      type: GET_LATEST_PENDING,
  };
}

export function fetchMoreLatestPending() {
  return {
      type: GET_MORE_LATEST_PENDING,
  };
}

export function fetchLatestSuccess(latest) {
  return {
      type: GET_LATEST_SUCCESS,
      payload: {
          latest: latest,
      },
  };
}

export function fetchMoreLatestSuccess(latest) {
  return {
      type: GET_MORE_LATEST_SUCCESS,
      payload: {
          latest: latest,
      },
  };
}